import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { useField } from 'formik';
import { isNil } from 'ramda';
import * as React from 'react';

export type Props = {
  name: string;
  label: string;
  error?: boolean;
  helperText?: string;
} & TextFieldProps;

export const FormikTextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label,
      error,
      helperText = '',
      onChange,
      onBlur,
      ...textFieldProps
    },
    ref
  ) => {
    const [field, meta] = useField(name);
    return (
      <TextField
        ref={ref}
        id={name}
        label={label}
        value={field.value}
        error={isNil(error) ? !!meta.error && meta.touched : error}
        helperText={meta.error || helperText}
        onChange={onChange || field.onChange}
        onBlur={onBlur || field.onBlur}
        {...textFieldProps}
      />
    );
  }
);
